<ng-container *ngIf="biolink">
  <div class="content">
    <ng-container *ngFor="let item of biolink.content">
      <ng-container *ngIf="item.active">
        <biolink-page-button
          *ngIf="item.model_type === 'link'"
          [link]="item"
          [style]="appearance?.btnConfig"
        ></biolink-page-button>

        <ng-container [ngSwitch]="item.type">
          <div class="widget socials-widget" *ngSwitchCase="'socials'">
            <social-button-list [config]="item.config"></social-button-list>
          </div>
          <div class="widget image-widget" [ngClass]="item.config.type" *ngSwitchCase="'image'">
            <a [href]="item.config.destinationUrl">
              <image-or-icon [src]="item.config.url" class="inherit-size"></image-or-icon>
            </a>
          </div>
          <div class="widget text-widget" *ngSwitchCase="'text'">
            <h2 class="title">{{ item.config.title }}</h2>
            <p class="description" *ngIf="item.config.description">{{ item.config.description }}</p>
          </div>
          <div class="widget youtube-embed" *ngSwitchCase="'youtubeEmbed'">
            <iframe
              [src]="trustUrl('https://www.youtube.com/embed/' + getVimeoEmbedId(item.config.url))"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div
            class="widget soundcloud-embed"
            *ngSwitchCase="'soundcloudEmbed'"
            [innerHTML]="trustHtml(item.config.embedCode)"
          ></div>
          <div class="widget youtube-embed" *ngSwitchCase="'vimeoEmbed'">
            <iframe
              [src]="trustUrl('https://player.vimeo.com/video/' + getVimeoEmbedId(item.config.url))"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="widget spotify-embed" *ngSwitchCase="'spotifyEmbed'">
            <iframe
              [src]="getSpotifyEmbedUrl(item.config.url)"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="widget twitch-embed" *ngSwitchCase="'twitchEmbed'">
            <iframe
              [src]="getTwitchEmbedUrl(item.config.url)"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="widget" *ngSwitchCase="'tiktokEmbed'">
            <blockquote [attr.data-video-id]="getTiktokVideoId(item.config.url)" class="tiktok-embed">
              <section><img [src]="settings.getAssetUrl('widgets/tiktok.png')" alt="" /></section>
            </blockquote>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
