<ng-container [ngSwitch]="style?.type">
  <a
    [href]="link.short_url"
    *ngSwitchCase="'basic'"
    mat-button
    color="accent"
    [ngClass]="style?.variant"
    [style.background-color]="style?.color"
  >
    <image-or-icon class="inherit-size" [src]="link.image" *ngIf="link.image"></image-or-icon>
    <div class="content">{{ link.name }}</div>
  </a>
  <a
    [href]="link.short_url"
    *ngSwitchCase="'stroked'"
    mat-stroked-button
    color="accent"
    [ngClass]="style?.variant"
    [style.background-color]="style?.color"
  >
    <image-or-icon class="inherit-size" [src]="link.image" *ngIf="link.image"></image-or-icon>
    <div class="content">{{ link.name }}</div>
  </a>
  <a
    [href]="link.short_url"
    *ngSwitchCase="'flat'"
    mat-flat-button
    color="accent"
    [ngClass]="style?.variant"
    [style.background-color]="style?.color"
  >
    <image-or-icon class="inherit-size" [src]="link.image" *ngIf="link.image"></image-or-icon>
    <div class="content">{{ link.name }}</div>
  </a>
  <a
    [href]="link.short_url"
    *ngSwitchDefault
    mat-raised-button
    color="accent"
    [ngClass]="style?.variant"
    [style.background-color]="style?.color"
  >
    <image-or-icon class="inherit-size" [src]="link.image" *ngIf="link.image"></image-or-icon>
    <div class="content">{{ link.name }}</div>
  </a>
</ng-container>
